<template>
    <div class="float-panel">
        <div @click="shopCart()" class="shopIcon">
            <div v-if="shopNum" class="shopNum">{{shopNum}}</div>
            <span class="icon iconfont icon-chaoshisjcs"></span>
            <div>采购车</div>
        </div>
        <!-- <div><img src="@/assets/images/sale/mall/taobao@2x.png"><div>淘宝店</div></div>
        <div><img src="@/assets/images/sale/mall/tianmao@2x.png"><div>天猫店</div></div>
        <div><img src="@/assets/images/sale/mall/pinduoduo@2x.png"><div>拼多多</div></div> -->
        <div @click="goto('/center')"><span class="icon iconfont icon-tihuan"></span><div>回工作台</div></div>
    </div>
</template>

<script>
    import { countPurCar } from '@/service/sale';
    export default {
        components: {

        },
        data() {
            return {
                // shopNum:this.$store.state.shopNum
            }
        },
        computed:{
            shopNum(){
                return this.$store.state.ShopNum;
            }
        },
        created() {
            this.getShopNum();
        },
        methods: {
            goto(path) {
                this.$router.push(path);
            },
            shopCart() {
                this.$router.push('/sale/pur/cgc');
            },
            getShopNum() {
                countPurCar().then(rst => {
                    console.log('countPurCar',rst);
                    this.$store.state.ShopNum = rst;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    .shopIcon {
        position: relative
    }
    .shopIcon .shopNum {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        background: red;
        font-size: 12px;
        color: #fff;
        position: absolute;
    }
    .float-panel{
        position: absolute;
        right: 0;
        top: 200px;
        top: 37%;
        z-index: 999;
        background: #fff;
        border-radius: 10px 0 0 10px;
        text-align: center;
        box-shadow: rgb(136, 136, 136) 5px 5px 5px;
        border: 1px solid #eee;
        overflow: hidden;
    }
    .float-panel>*{
        cursor: pointer;
        padding: 10px 15px;
    }
    .float-panel>*:hover{
        background: #eee;
    }
    .float-panel .icon{
        font-size: 34px;
    }
    .float-panel img{
        width: 34px;
        height: 34px;
    }
</style>